import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../data/api/api';
import {exportLogKeySlice} from './slices';

function* exportLog(slice, endpoint, action) {
    try {
        const { teamGroupId, importLogId } = action.payload;
        const data = yield call(endpoint, teamGroupId, importLogId);
        yield put(slice.actions.exportSuccess(data));
    } catch (error) {
        yield put(slice.actions.exportFailure(error?.response?.data?.error ?? 'UNKNOWN'));
    }
}

function* watchExportLog(slice, endpoint) {
    yield takeLatest(slice.actions.exportStart.type, exportLog, slice, endpoint);
}

export default function* exportLogSaga() {
    yield all([
        watchExportLog(exportLogKeySlice, api.importLogs.exportLog),
    ]);
}
