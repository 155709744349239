import React from "react";

const UsePagination = ({ defaultPage = 1, defaultRowPerPage = 10, dataLength }) => {
    const [page, setPage] = React.useState(defaultPage);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowPerPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(defaultPage);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return { pagination: { page, rowsPerPage }, paginationCount: Math.ceil(dataLength / rowsPerPage), handleChangeRowsPerPage, handleChangePage };
}

export default UsePagination;
