import React, { useMemo, useState } from 'react';
import { Typography } from '@material-ui/core';
import { Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { withStyles } from '@mui/styles';
import { PersonalizationTab } from './components/PersonalizationTab';
import { RegulationsTab } from './components/RegulationsTab';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { ParametersTabs } from './components/ParametersTab';
import { AdministrationUrl } from '../../enums/administrationUrl';
import { TitleContainer } from '../../components/TitleContainer';

const styles = {
  adminBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  adminHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
  },
};

const Admin = ({ ...props }) => {
  const intl = useIntl();
  const history = useHistory();
  const { contract, tab } = useParams();

  const REGULATIONS_TAB_INDEX = '1';
  const PERSONALIZATION_TAB_INDEX = '2';
  const PARAMETERS_TAB_INDEX = '3';

  const tabs = useMemo(
    () => [
      {
        label: intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.adminTabLabel.regulations',
        }),
        index: REGULATIONS_TAB_INDEX,
        url: AdministrationUrl.REGULATIONS,
      },
      {
        label: intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.adminTabLabel.personalization',
        }),
        index: PERSONALIZATION_TAB_INDEX,
        url: AdministrationUrl.PERSONALIZATION,
      },
      {
        label: intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.adminTabLabel.parameters',
        }),
        index: PARAMETERS_TAB_INDEX,
        url: AdministrationUrl.PARAMETERS,
      },
    ],
    [intl],
  );

  const currentTab = useMemo(() => {
    return tabs.find(tabItem => tabItem.url === tab);
  }, [tab, tabs]);
  const [tabValue, setTabValue] = useState(
    currentTab ? currentTab.index : REGULATIONS_TAB_INDEX,
  );

  const handleChange = (_, tabValue) => {
    setTabValue(tabValue);
    const selectedTab = tabs.find(tabItem => tabItem.index === tabValue);
    if (selectedTab) {
      history.push(`/nodes/${contract}/administration/${selectedTab.url}`, {
        shallow: true,
      });
    }
  };

  return (
    <div className={props.classes.adminBody}>
      <TitleContainer>
        <div className={props.classes.adminHeader}>
          <Typography
            variant={'h1'}
            component={'h1'}
            className={'underline-left'}
          >
            {intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.title',
            })}
          </Typography>
        </div>
      </TitleContainer>
      <TabContext value={tabValue}>
        <div className={'tabs-container'}>
          <TabList
            onChange={handleChange}
            variant='scrollable'
            scrollButtons='on'
          >
            {tabs.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  label={tab.label}
                  value={tab.index}
                  disableFocusRipple
                />
              );
            })}
          </TabList>
          <TabPanel value={REGULATIONS_TAB_INDEX}>
            <RegulationsTab />
          </TabPanel>
          <TabPanel value={PERSONALIZATION_TAB_INDEX}>
            <PersonalizationTab />
          </TabPanel>
          <TabPanel value={PARAMETERS_TAB_INDEX}>
            <ParametersTabs />
          </TabPanel>
        </div>
      </TabContext>
    </div>
  );
};

export default withStyles(styles)(Admin);
