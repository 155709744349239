import getSystemImageReducers from './getSystemFiles/slices';
import updateSystemImageReducers from './updateSystemFiles/slices';
import deleteSystemImageReducers from './deleteSystemFiles/slices';
import approveSystemImageReducers from './approveSystemFiles/slices';

export default {
    ...getSystemImageReducers,
    ...updateSystemImageReducers,
    ...deleteSystemImageReducers,
    ...approveSystemImageReducers
};
