import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    logFile: null,
    success: false,
    loading: false,
    error: null,
};

const exportLogSlice = (name) => createSlice({
    name: name,
    initialState,
    reducers: {
        exportStart: (state) => {
            state.success = false;
            state.loading = true;
            state.error = null;
        },
        exportSuccess: (state, data) => {
            state.logFile = data.payload
            state.success = true;
            state.loading = false;
            state.error = null;
        },
        exportFailure: (state, action) => {
            state.success = false;
            state.loading = false;
            state.error = action.payload;
        },
        clearExportLogState: () => initialState,
    },
});

export const exportLogKeySlice = exportLogSlice('exportLogKey');

export const {
    exportStart: exportLogKeyStart,
    exportSuccess: exportLogKeySuccess,
    exportFailure: exportLogKeyFailure,
    clearExportLogState: exportLogKeyClear,
} = exportLogKeySlice.actions;

export default {
    exportLogKey: exportLogKeySlice.reducer,
};
