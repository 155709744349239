import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../data/api/api';
import { getRewardsKeySlice } from './slices';

function* fetchGetRewards(slice, endpoint, action) {
    try {
        const { teamGroupId } = action.payload;
        const data = yield call(endpoint, teamGroupId);
        yield put(slice.actions.fetchSuccess(data));
    } catch (error) {
        yield put(slice.actions.fetchFailure(error?.response?.data?.error ?? 'UNKNOWN'));
    }
}

function* watchGetRewards(slice, endpoint) {
    yield takeLatest(slice.actions.fetchStart.type, fetchGetRewards, slice, endpoint);
}

export default function* contractDetailsSaga() {
    yield all([
        watchGetRewards(getRewardsKeySlice, api.rewards.listDistributors),
    ]);
}
