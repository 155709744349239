import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CloseCircle } from "iconsax-react";
import {
    Button,
    Drawer,
    CircularProgress,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { HierarchyNodeUser } from "./components";
import { allHierarchyNodeUsersFetchingStart } from "../../../../../../../../../features/base/allPagesFetchingFromApi/slices";
import {
    setHierarchyNodeUser,
    useAuth,
} from "../../../../../../../../../../auth";
import { HierarchyNodeUserStatuses } from "../../../../../../../../../enums";

const useStyles = makeStyles((theme) => ({
    userList: {
        alignItems: "center",
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "column",
        gap: "18px",
    },
}));

const HierarchyNodeUserSwitcher = ({ open, setOpen, handleDrawerToggle }) => {
    const { hierarchyNodeUser } = useAuth();
    const dispatch = useDispatch();
    const intl = useIntl();
    const { contract } = useParams();
    const classes = useStyles();
    const [selectedUser, setSelectedUser] = useState(null);

    const allHierarchyNodeUsersFetchingState = useSelector(
        (state) => state.allHierarchyNodeUsersFetching
    );
    const { data, loading } = allHierarchyNodeUsersFetchingState;
    const hasUsers = Array.isArray(data) && data.length > 0;
    const handleClose = (force = false) => {
        if (force || hierarchyNodeUser != null) {
            setOpen(false);
        }
    };

    const handleUserClick = (user) => {
        const userActive = user.status !== HierarchyNodeUserStatuses.INACTIVE;

        if (!userActive || hierarchyNodeUser.uuid === user.uuid) return;

        dispatch(setHierarchyNodeUser(user));
        if (handleDrawerToggle) {
            handleDrawerToggle();
        }
        handleClose(true);
    };

    const RenderLoading = () => <CircularProgress />;

    const RenderUsers = () => {
        return (
            <div className={classes.userList}>
                {data.map((user) => (
                    <HierarchyNodeUser
                        key={user.uuid}
                        user={user}
                        isSelectable={user.uuid !== hierarchyNodeUser?.uuid}
                        isSelected={selectedUser?.uuid === user.uuid}
                        onClick={() => setSelectedUser(user)}
                    />
                ))}
            </div>
        );
    };

    useEffect(() => {
        // todo: see how to prevent the opening here with due to code team update and the difference between the url contract and the hierarchy_node.code from the hierarchyNodeUser
        if (
            (hierarchyNodeUser === null ||
                hierarchyNodeUser._context.hierarchy_node.code !== contract) &&
            !open
        ) {
            setOpen(true);
        }

        return () => {
            setSelectedUser(null);
        };
    }, [contract, hierarchyNodeUser, open, setOpen]);

    useEffect(() => {
        dispatch(allHierarchyNodeUsersFetchingStart([contract, true]));
    }, [contract, dispatch]);

    return (
        <>
            <Drawer anchor={"right"} open={open} onClose={handleClose}>
                <div className={"header"}>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        size={"small"}
                        className={"icon light"}
                        onClick={handleClose}
                    >
                        <CloseCircle />
                    </Button>
                </div>
                <div className={"content"}>
                    <Typography variant={"h2"} component={"h2"} className={"title"}>
                        {intl.formatMessage({
                            id: "spider.hierarchy_node_user_switcher.title",
                        })}
                    </Typography>
                    {hasUsers ? <RenderUsers /> : loading ? <RenderLoading /> : null}
                    <Button
                        disabled={
                            !selectedUser || hierarchyNodeUser?.uuid === selectedUser.uuid
                        }
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => handleUserClick(selectedUser)}
                    >
                        {intl.formatMessage({
                            id: "spider.common.submit",
                        })}
                    </Button>
                </div>
            </Drawer>
        </>
    );
};

export default HierarchyNodeUserSwitcher;
