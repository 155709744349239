import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import * as configListActions from '../../../../../../services/Configs/ConfigList/actions';
import * as configListUpdateActions from '../../../../../../services/Configs/ConfigListUpdate/actions';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Formsy from 'formsy-react';
import { Switch, ProgressButton } from '../../../../../../components';
import { Grid } from '@material-ui/core';

const BadgeSettings = ({ ...props }) => {
  const intl = useIntl();
  const history = useHistory();
  const { configs, loading } = props.configList;
  const { success, error } = props.configListUpdate;
  const [formState, setFormState] = useState({
    BSCB: configs.find((x) => x.code === 'BSCB').value.toBoolean(),
  });

  const handleChange = (code, value) => {
    setFormState((prev) => ({ ...prev, [code]: value }));
  };

  const handleSubmit = () => {
    const configsToUpdate = [
      { id: configs.find((x) => x.code === 'BSCB').id, value: formState.BSCB },
    ];
    props.configListUpdateActions.updateConfigList(configsToUpdate);
  };

  useEffect(() => {
    if (success) {
      props.configListUpdateActions.clearConfigListUpdate();
      toast.success(
        intl.formatMessage({ id: 'common.update_success_message' })
      );
      history.push('/admin');
    }
    if (error) {
      toast.error(intl.formatMessage({ id: 'common.update_error_message' }));
    }
  }, [success, error, props.configListUpdateActions]);

  return (
    <div>
      <Formsy onValidSubmit={handleSubmit}>
        <Switch
          isContrast
          name='badge_showing_collaborators'
          label={intl.formatMessage({
            id: 'admin.badge_config.settings.badge_showing_collaborators',
          })}
          initial={formState.BSCB}
          onChange={(value) => handleChange('BSCB', value)}
          disabled={loading}
        />
        <Grid item xs={12}>
          <ProgressButton
            type='submit'
            text={intl.formatMessage({ id: 'common.submit' })}
            centered
            loading={loading}
          />
        </Grid>
      </Formsy>
    </div>
  );
};

const mapStateToProps = ({ configList, configListUpdate }) => ({
  configList,
  configListUpdate,
});

const mapDispatchToProps = (dispatch) => ({
  configListActions: bindActionCreators(configListActions, dispatch),
  configListUpdateActions: bindActionCreators(
    configListUpdateActions,
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(BadgeSettings);
