import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    imageUpdated: null,
    success: false,
    loading: false,
    error: null,
};

const createUpdateRewardAllocationsImageSlice = (name) => createSlice({
    name: name,
    initialState,
    reducers: {
        updateStart: (state) => {
            state.imageUpdated = null;
            state.success = false;
            state.loading = true;
            state.error = null;
        },
        updateSuccess: (state, data) => {
            state.imageUpdated = data.payload
            state.success = true;
            state.loading = false;
            state.error = null;
        },
        updateFailure: (state, action) => {
            state.imageUpdated = null;
            state.success = false;
            state.loading = false;
            state.error = action.payload;
        },
        clearUpdateRewardsAllocationsImageState: (state) => {
            state.success = false;
            state.loading = false;
            state.error = null;
        }
    },
});

export const updateRewardsAllocationsImageKeySlice = createUpdateRewardAllocationsImageSlice('updateRewardsAllocationsImageKey');

export const {
    updateStart: updateRewardsAllocationsImageKeyStart,
    updateSuccess: updateRewardsAllocationsImageKeySuccess,
    updateFailure: updateRewardsAllocationsImageKeyFailure,
    clearUpdateRewardsAllocationsImageState: updateRewardsAllocationsImageKeyClear,
} = updateRewardsAllocationsImageKeySlice.actions;

export default {
    updateRewardsAllocationsImageKey: updateRewardsAllocationsImageKeySlice.reducer
};
