import React, {useState} from 'react';
import {withStyles} from "@mui/styles";
import Formsy from "formsy-react";
import {FormControl, FormControlLabel, Switch, Typography} from "@material-ui/core";
import Button from "../../../../../../components/Button";
import useDirtyPristine from "../../../../../../hooks/UseDirtyPristine";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {themeColors} from "../../../../../../themes";
import {InfoCircle} from "iconsax-react";
import useHandleToast from "../../../../../../hooks/UseHandleToast";
import {
    updateContractDetailsKeyClear,
    updateContractDetailsKeyStart
} from "../../../../../../features/contractDetails/updateContractDetails/slices";
import {LfssStatus} from "../../../../../../enums/lfssStatus";
import {useParams} from "react-router-dom";

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
    },
    titleGroup: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
        alignItems: 'center'
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
        justifyContent: 'center',
        alignItems: 'center'
    }
}

const LFSSParameters = ({ ...props }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { contract } = useParams();
    const { dirtyChange, resetPristinePage } = useDirtyPristine();

    const getContractDetails = useSelector(state => state.getContractDetailsKey);
    const updateContractDetailsKey = useSelector(state => state.updateContractDetailsKey);

    const [lfssEnabled, setLfssEnabled] = useState(getContractDetails.contractDetails ? getContractDetails.contractDetails.lfss_form_status === LfssStatus.ACTIVE : false);
    const [listener, setListener] = useState(false)

    const clearListener = () => {
        setListener(false)
    }

    useHandleToast(updateContractDetailsKey, dispatch, updateContractDetailsKeyClear,
        'spider.hierarchy_node.platform_customization.parameters.contractDetails.update.success',
        'spider.hierarchy_node.platform_customization.parameters.contractDetails.update.error', listener, clearListener)

    const checkLfss = (event) => {
        dirtyChange()
        setLfssEnabled(event.target.checked)
    }

    const savePlatformContractDetails = () => {
        setListener(true)
        resetPristinePage()
        dispatch(updateContractDetailsKeyStart({
            teamGroupId: contract,
            contractDetails: {
                lfss_form_status: lfssEnabled ? LfssStatus.ACTIVE : LfssStatus.INACTIVE
            }
        }))
    }

    return (
        <Formsy name={'parameter-contract-details'} onValidSubmit={savePlatformContractDetails} className={props.classes.root}>
            <div className={props.classes.titleGroup}>
                <Typography variant={"h4"} component={"h4"}>
                    {intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.title'})}
                </Typography>
                <InfoCircle color={themeColors.secondaryRegular}/>
            </div>

            <Typography variant={"body1"}>
                {intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.subtitle'})}
            </Typography>

            <FormControl style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel
                    checked={lfssEnabled}
                    control={<Switch color={'primary'}/>}
                    labelPlacement="start"
                    label={lfssEnabled ? intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.lfssEnabledLabel'}) :
                        intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.lfssDisabledLabel'})} style={{ margin: 0 }}
                    onChange={checkLfss}
                />
            </FormControl>

            <div className={props.classes.actions}>
                <Button color={'primary'} variant={'outlined'} size={'small'} type={'submit'}>
                    {intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.saveButtonLabel'})}
                </Button>
            </div>
        </Formsy>
    )
}

export default withStyles(styles)(LFSSParameters);
