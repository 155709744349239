import React, {createContext, useContext, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useParams} from 'react-router-dom';
import {CircularProgress} from "@material-ui/core";

import {fetchActiveHierarchyNodeUserStart} from "./features/hierarchyNodeUser/slices";
import {getUser, login, loginCallback, logout as oidcLogout, register, registerCallback} from './oidc';

const AuthContext = createContext(null);

export const AuthProvider = ({children, disableAutoRedirect = false}) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const {contract} = useParams();
    const {hierarchyNodeUser, loading} = useSelector(state => state.activeHierarchyNodeUser);

    const logout = () => {
        oidcLogout();
    }

    useEffect(() => {
        if (disableAutoRedirect) {
            return;
        }

        const initAuth = async () => {
            const loggedUser = await getUser();

            if (!loggedUser) {
                const encodedPath = encodeURIComponent(location.pathname);
                const redirectUri = `${window.location.origin}/callback?redirect=${encodedPath}`;
                await login(redirectUri);
                return;
            }

            if (contract && !hierarchyNodeUser) {
                dispatch(fetchActiveHierarchyNodeUserStart({contract}));
            }
        };

        initAuth();
    }, [location.pathname]);

    const renderLoader = () => {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <CircularProgress/>
            </div>
        );
    }

    const contextValue = useMemo(() => ({
        login,
        loginCallback,
        logout,
        register,
        registerCallback,
        hierarchyNodeUser
    }), [hierarchyNodeUser]);

    const renderChildren = () => {
        return (
            <AuthContext.Provider value={contextValue}>
                {children}
            </AuthContext.Provider>
        );
    }

    const fullLogged = disableAutoRedirect || !contract || (!loading && hierarchyNodeUser);

    return (
        <>
            {!fullLogged && renderLoader()}
            {fullLogged && renderChildren()}
        </>
    );
};

export const useAuth = () => useContext(AuthContext);
