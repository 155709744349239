import React from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Reward } from './components/Reward';
import { withStyles } from '@mui/styles';

const styles = {
  rewardLayout: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  rewards: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
};

const ProductsParameters = ({ ...props }) => {
  const intl = useIntl();
  const rewards = useSelector(state => state.getRewardsKey);
  const renderRewards = () => {
    return rewards.rewards.results.map(reward => {
      return <Reward key={reward.uuid} reward={reward} />;
    });
  };

  return (
    <div className={props.classes.rewardLayout}>
      <Typography variant={'h4'} component={'h4'}>
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.rewards.title',
        })}
      </Typography>

      <Typography variant={'subtitle1'}>
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.rewards.subtitle',
        })}
      </Typography>

      <div className={props.classes.rewards}>{renderRewards()}</div>
    </div>
  );
};

export default withStyles(styles)(ProductsParameters);
