import instance from "../instance";

const ContractDetails = {
    get(code) {
        return instance.get(`hierarchy-nodes/${code}/contract-detail`);
    },
    update(code, contractDetail) {
        return instance.patch(`hierarchy-nodes/${code}/contract-detail/`, contractDetail)
    }
}

export default ContractDetails;
