import {neutralColors, systemColors, themeColors} from "../colors";
import {hexColorToSvgFillColor} from "../../helper/Color";

const CheckboxStyles = {
    MuiFormControlLabel: {
        root: {
            display: 'flex',
            alignItems: 'center',
            gap: '0.75rem',
        }
    },
    MuiButtonBase: {
        root: {
            '&.MuiCheckbox-root': {
                '&.MuiIconButton-root': {
                  '&:focus': {
                      border: 'none'
                  },
                  '&:active': {
                      border: 'none'
                  }
                },
                '&:hover': {
                    backgroundColor: `transparent !important`,
                    '& .MuiIconButton-label': {
                        '& .icon': {
                            borderColor: themeColors.secondaryLight,
                        },
                        '& .checkedIcon': {
                            backgroundColor: themeColors.secondaryLight,
                        }
                    }
                },
                '&:focus': {
                    '& .MuiIconButton-label': {
                        '& .icon': {
                            borderColor: systemColors.infoLight,
                        },
                        '& .checkedIcon': {
                            backgroundColor: 'transparent',
                            '&:before': {
                                backgroundImage:
                                    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath" +
                                    " fill-rule='evenodd' clip-rule='evenodd' d='M17.6899 8.27607C18.0897 8.65707 18.1049 9.29005 17.7239 9.68987L11.0533 16.6899C10.8645 16.888 10.6027 17.0001 10.329 17C10.0552 16.9999 9.79353 16.8876 9.60487 16.6892L6.27546 13.1892C5.89481 12.7891 5.91062 12.1561 6.31077 11.7755C6.71093 11.3948 7.34389 11.4106 7.72455 11.8108L10.3301 14.5498L16.2761 8.31013C16.6571 7.91032 17.2901 7.89506 17.6899 8.27607Z'" +
                                    ` fill='${hexColorToSvgFillColor(themeColors.secondaryRegular)}'/%3E%3C/svg%3E")`,
                            }
                        }
                    }
                },
                '&:disabled': {
                    '& .MuiIconButton-label': {
                        '& .icon': {
                            borderColor: neutralColors.neutral300,
                        },
                    }
                },
                '& .MuiIconButton-label': {
                    '& .icon': {
                        display: 'block',
                        borderRadius: '0.5rem',
                        width: '1.5rem',
                        height: '1.5rem',
                        backgroundColor: neutralColors.neutralWhite,
                        border: `2px solid ${neutralColors.neutral450}`,
                        '&:before': {
                            display: 'block',
                            width: '1.5rem',
                            height: '1.5rem',
                            content: '""'
                        }
                    },
                    '& .checkedIcon': {
                        backgroundColor: themeColors.secondaryRegular,
                        border: 'none',
                        '&:before': {
                            display: 'block',
                            width: '1.5rem',
                            height: '1.5rem',
                            backgroundImage:
                                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath" +
                                " fill-rule='evenodd' clip-rule='evenodd' d='M17.6899 8.27607C18.0897 8.65707 18.1049 9.29005 17.7239 9.68987L11.0533 16.6899C10.8645 16.888 10.6027 17.0001 10.329 17C10.0552 16.9999 9.79353 16.8876 9.60487 16.6892L6.27546 13.1892C5.89481 12.7891 5.91062 12.1561 6.31077 11.7755C6.71093 11.3948 7.34389 11.4106 7.72455 11.8108L10.3301 14.5498L16.2761 8.31013C16.6571 7.91032 17.2901 7.89506 17.6899 8.27607Z'" +
                                ` fill='${hexColorToSvgFillColor(neutralColors.neutralWhite)}'/%3E%3C/svg%3E")`,
                            content: '""',
                        }
                    }
                },
            }
        }
    }
}

export default CheckboxStyles;
