import React, { useState } from 'react';
import TextField from '../../../../../../../../../../../../components/TextField/TextField';
import { useIntl } from 'react-intl';
import { RewardAllocationConfigurationType } from '../../../../../../../../../../../../enums/rewardAllocationConfigurationType';

const ConfigurationField = ({
  configuration,
  configurationDefaultValue = '',
  onConfigurationChange,
}) => {
  const intl = useIntl();

  const [configurationValue, setConfigurationValue] = useState(
    configurationDefaultValue,
  );

  const configurationValueChanged = e => {
    let value = '';
    switch (configuration.type) {
      case RewardAllocationConfigurationType.INTEGER:
        if (e.target.value !== '') {
          value = parseInt(e.target.value);
        }
        break;
      case RewardAllocationConfigurationType.DECIMAL:
        if (e.target.value !== '') {
          value = parseFloat(e.target.value);
        }
        break;
      default:
        value = e.target.value;
        break;
    }
    setConfigurationValue(value);
    onConfigurationChange({ code: configuration.code, value });
  };

  const getValidation = () => {
    let validations = {};
    switch (configuration.type) {
      case RewardAllocationConfigurationType.INTEGER:
        validations = {
          isInt: true,
          isPositive: true,
        };
        break;
      case RewardAllocationConfigurationType.DECIMAL:
        validations = {
          isNumeric: true,
          isPositive: true,
        };
        break;
      default:
        break;
    }

    return validations;
  };

  const getValidationErrors = () => {
    let validationsErrors = {};
    switch (configuration.type) {
      case RewardAllocationConfigurationType.INTEGER:
        validationsErrors = {
          isInt: intl.formatMessage({
            id: 'common.form.is_int_error',
          }),
          isPositive: intl.formatMessage({
            id: 'common.form.is_more_than_or_equals_0_error',
          }),
        };
        break;
      case RewardAllocationConfigurationType.DECIMAL:
        validationsErrors = {
          isNumeric: intl.formatMessage({
            id: 'common.form.numeric_error',
          }),
          isPositive: intl.formatMessage({
            id: 'common.form.is_more_than_or_equals_0_error',
          }),
        };
        break;
      default:
        break;
    }

    return {
      isDefaultRequiredValue: intl.formatMessage({
        id: 'common.form.required_error',
      }),
      ...validationsErrors,
    };
  };

  const getType = () => {
    let type;
    switch (configuration.type) {
      case RewardAllocationConfigurationType.INTEGER:
        type = 'number';
        break;
      case RewardAllocationConfigurationType.DECIMAL:
        type = 'number';
        break;
      default:
        type = 'text';
        break;
    }

    return type;
  };

  return (
    <TextField
      name={'configurationField'}
      value={configurationValue}
      label={intl.formatMessage({
        id:
          'spider.hierarchy_node.platform_customization.parameters.rewards.fieldCodes.' +
          configuration.code,
      })}
      variant={'outlined'}
      type={getType()}
      required
      inputProps={{
        min: getType() === 'number' ? '0' : undefined,
      }}
      validations={getValidation()}
      validationErrors={getValidationErrors()}
      onChange={configurationValueChanged}
    />
  );
};

export default ConfigurationField;
