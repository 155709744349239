import instance from '../instance';
import PaginationHelper from "./helper/paginationHelper";

const baseUrl = 'import-logs/';

const importLogs = {
  list() {
    return instance.get(baseUrl);
  },
  export(id) {
    return instance.get(`${baseUrl}${id}/export`);
  },
  hierarchyNodeList (teamId, types, pagination) {
    const params = new URLSearchParams();
    if (types && types.length > 0) {
      types.forEach((type) => {
        params.append('types', type);
      });
    }
    return instance.get(`hierarchy-nodes/${teamId}/import-logs`, { params: PaginationHelper(pagination, params) });
  },
  createImport (teamId, importFile) {
    return instance.post(`hierarchy-nodes/${teamId}/import-logs/`, importFile);
  },
  exportLog (teamId, importLogId) {
    const params = new URLSearchParams();

    params.append('import_log', importLogId);

    return instance.get(`hierarchy-nodes/${teamId}/export-logs`, { params });
  }
};

export default importLogs;
