import instance from '../instance';
import PaginationHelper from './helper/paginationHelper';

/**
 * @param {string} id ID du noeud hiérarchique en base de données
 */
const baseUrl = id => `hierarchy-nodes/${id}/transactions/`;

const pointsTransactions = {
  /**
   * @param {string} id ID du noeud hiérarchique en base de données
   * @param {number} [page] Page à retourner
   */
  list(id, page) {
    const params = new URLSearchParams();
    return instance.get(baseUrl(id), {
      params: PaginationHelper(page, params),
    });
  },
};

export default pointsTransactions;
