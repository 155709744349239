import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../data/api/api';
import {getHistoryImportsKeySlice} from './slices';

function* fetchHistoryImports(slice, endpoint, action) {
    try {
        const { teamGroupId, types, pagination } = action.payload;
        const data = yield call(endpoint, teamGroupId, types, pagination);
        yield put(slice.actions.fetchSuccess(data));
    } catch (error) {
        yield put(slice.actions.fetchFailure(error?.response?.data?.error ?? 'UNKNOWN'));
    }
}

function* watchHistoryImports(slice, endpoint) {
    yield takeLatest(slice.actions.fetchStart.type, fetchHistoryImports, slice, endpoint);
}

export default function* crudHistoryImportsSaga() {
    yield all([
        watchHistoryImports(getHistoryImportsKeySlice, api.importLogs.hierarchyNodeList),
    ]);
}
