import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    contractDetails: null,
    success: false,
    loading: false,
    error: null,
};

const createGetContactDetailsSlice = (name) => createSlice({
    name: name,
    initialState,
    reducers: {
        fetchStart: (state) => {
            state.success = false;
            state.loading = true;
            state.error = null;
        },
        fetchSuccess: (state, data) => {
            state.contractDetails = data.payload.data
            state.success = true;
            state.loading = false;
            state.error = null;
        },
        fetchFailure: (state, action) => {
            state.success = false;
            state.loading = false;
            state.error = action.payload;
        },
        clearCrudContractDetailsState: () => initialState,
    },
});

export const getContractDetailsKeySlice = createGetContactDetailsSlice('getContractDetailsKey');

export const {
    fetchStart: getContractDetailsKeyStart,
    fetchSuccess: getContractDetailsKeySuccess,
    fetchFailure: getContractDetailsKeyFailure,
    clearCrudContractDetailsState: getContractDetailsKeyClear,
} = getContractDetailsKeySlice.actions;

export default {
    getContractDetailsKey: getContractDetailsKeySlice.reducer,
};
