import React from 'react';
import { useAuth } from '../../../auth';
import { UserRoles } from '../../enums';
import { AdminHome } from './components/AdminHome';
import { UserHome } from './components/UserHome';
import { Container } from '@material-ui/core';

const Points = () => {
  const { hierarchyNodeUser } = useAuth();

  return (
    <Container maxWidth='xl'>
      {hierarchyNodeUser.role === UserRoles.ADMINISTRATOR && <AdminHome />}
      {hierarchyNodeUser.role === UserRoles.COLLABORATOR && <UserHome />}
    </Container>
  );
};

export default Points;
