import {themeColors, gradients, neutralColors} from '../colors';

const listItemStyles = (configObject) => {
    return {
        MuiListItem: {
            root: {
                borderRadius: 16,
                height: 40,
                '&:not(:last-child)': {
                    marginBottom: 8,
                },
                transition: 'background-color 0.3s, color 0.3s',
                '&.Mui-selected': {
                    background: configObject.mainColor.value,
                    color: themeColors.primaryContrastText,
                    '& .MuiListItemText-primary, & .MuiListItemIcon-root': {
                        color: themeColors.primaryContrastText,
                        fontWeight: 700,
                        transition: 'color 0.3s',
                    },
                    '&:focus': {
                        border: `none`,
                        background: configObject.mainColor.value,
                        color: themeColors.primaryContrastText,
                    },
                },
                '&:focus': {
                    border: `1px solid ${neutralColors.neutral450}`,
                    background: neutralColors.neutralWhite,
                    color: neutralColors.neutral900,
                },
            },
            button: {
                '&:hover': {
                    background: `${configObject.mainColor.value} !important`,
                    opacity: 0.7,
                    color: neutralColors.neutral900,
                    '& .MuiListItemText-primary, & .MuiListItemIcon-root': {
                        color: neutralColors.neutral900,
                        fontWeight: 700,
                        transition: 'color 0.3s',
                    },
                },
                // '&:active, &.Mui-selected': {
                //   background: configObject.mainColor.value,
                //   color: themeColors.primaryContrastText,
                //   '& .MuiListItemText-primary, & .MuiListItemIcon-root': {
                //     color: themeColors.primaryContrastText,
                //     fontWeight: 700,
                //     transition: 'color 0.3s',
                //   },
                // },
            },
        },
        MuiListItemText: {
            root: {
                fontSize: 14,
                fontWeight: 400,
                lineHeight: 24,
            },
        },
        MuiListItemIcon: {
            root: {
                color: 'inherit',
            },
        },
    }
};

export default listItemStyles;
