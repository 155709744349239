import React from 'react'
import {withStyles} from "@mui/styles";
import {Typography} from "@material-ui/core";
import themeColors from "../../themes/colors/themeColors";

const styles = {
    alertWrapper: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
        padding: '1rem',
        backgroundColor: themeColors.secondaryBg,
        borderRadius: '0.75rem'
    },
    alertIcon: {
        width: '1.5rem',
        height: '1.5rem'
    },
    alertContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        textAlign: 'left'
    }
}

const Alert = ({ icon, content, title, ...props }) => {
    return (
        <div className={props.classes.alertWrapper}>
            <div className={props.classes.alertIcon}>
                {icon}
            </div>
            <div className={props.classes.alertContent}>
                <Typography variant={'h4'} component={'h4'} style={{ color: themeColors.secondaryRegular }}>
                    {title}
                </Typography>
                { content() }
            </div>
        </div>
    )
}

export default withStyles(styles)(Alert)
