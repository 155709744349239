import React from 'react';

const Help = () => {
    return (
        <div>
            Edenred home
        </div>
    );
};

export default Help;
