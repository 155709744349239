import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../data/api/api';
import { updateRewardsAllocationsImageKeySlice } from './slices';

function* updateRewardAllocationsImage(slice, endpoint, action) {
    try {
        const { rewardAllocationId, file, code } = action.payload;
        const data = yield call(endpoint, rewardAllocationId, file, code);
        yield put(slice.actions.updateSuccess(data.data));
    } catch (error) {
        yield put(slice.actions.updateFailure(error?.response?.data?.error ?? 'UNKNOWN'));
    }
}

function* watchUpdateRewardAllocationsImage(slice, endpoint) {
    yield takeLatest(slice.actions.updateStart.type, updateRewardAllocationsImage, slice, endpoint);
}

export default function* updateRewardAllocationsImageSaga() {
    yield all([
        watchUpdateRewardAllocationsImage(updateRewardsAllocationsImageKeySlice, api.rewardAllocations.updateImage),
    ]);
}
