const accordionStyles = {
    MuiAccordionSummary: {
        root: {

        }
    },
    MuiButtonBase: {
        root: {
            '& > MuiAccordionSummary-root': {
                gap: '1rem'
            }
        }
    }
}

export default accordionStyles
