import React from 'react';
import { withStyles } from '@mui/styles';
import { neutralColors } from '../../../../../../../../../../themes';
import CardSvg from '../../../../../../../../../../assets/img/carte-kadeo.svg';
import { Typography } from '@material-ui/core';
import { RewardAllocationsConfiguration } from './components/RewardAllocationsConfiguration';

const styles = {
  reward: {
    display: 'flex',
    padding: '1rem',
    flexDirection: 'column',
    gap: '2rem',
    alignSelf: 'stretch',
    borderRadius: '0.5rem',
    backgroundColor: neutralColors.neutralColdWhite,
  },
  rewardTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
  },
};

const RewardComponent = ({ reward, ...props }) => {
  const isActiveAllocation = () => {
    return reward._context ? reward._context.active_allocation !== null : false;
  };

  return (
    <div className={props.classes.reward}>
      <div className={props.classes.rewardTitle}>
        <img src={CardSvg} alt={'close-icon'} />

        <Typography variant={'h3'} component={'h3'}>
          {reward.name}
        </Typography>
      </div>

      <RewardAllocationsConfiguration
        rewardLabel={reward.name}
        rewardAllocationId={
          isActiveAllocation() ? reward._context.active_allocation.uuid : null
        }
        configurations={reward.individuals_required_config ?? []}
        activeConfigurations={
          isActiveAllocation()
            ? (reward._context.active_allocation.individuals_config ?? [])
            : []
        }
        physicPerson={true}
      />

      <RewardAllocationsConfiguration
        rewardLabel={reward.name}
        rewardAllocationId={
          isActiveAllocation() ? reward._context.active_allocation.uuid : null
        }
        configurations={reward.legal_entities_required_config ?? []}
        activeConfigurations={
          isActiveAllocation()
            ? (reward._context.active_allocation.legal_entities_config ?? [])
            : []
        }
      />
    </div>
  );
};

export default withStyles(styles)(RewardComponent);
