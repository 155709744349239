import React from 'react';

const UserHome = () => {
    return (
        <div>
            Edenred home
        </div>
    );
};

export default UserHome;
