import { all, call, put, takeLatest } from 'redux-saga/effects';
import {createMainKeySlice} from './slices';
import {SystemFileStatuses} from "../../enums/systemFileStatuses";
import {SystemFileCodes} from "../../enums";
import api from "../../../data/api/api";
import {ConfigCodes} from "../../enums/configCodes";

function* loadLegalDocuments(slice, action) {
    try {
        const { teamGroupId } = action;

        const legalDocuments = yield call(api.systemImages.hierarchyNodeList, teamGroupId,
            [SystemFileCodes.RGPD, SystemFileCodes.LEGAL_MENTIONS, SystemFileCodes.REGLEMENT],
            [SystemFileStatuses.ACTIVE]);

        yield put(slice.actions.loadLegalDocumentsSuccess({ legalDocuments }));
    } catch (error) {
        yield put(slice.actions.mainFailure(error?.response?.data ?? 'UNKNOWN'));
    }
}

function* loadMainConfigs(slice, action) {
    try {
        const { teamGroupId } = action;

        const configs = yield call(api.configs.permanent, teamGroupId, [ConfigCodes.MAIN_COLOR]);

        yield put(slice.actions.loadConfigsSuccess({ configs }));
    } catch (error) {
        yield put(slice.actions.mainFailure(error?.response?.data ?? 'UNKNOWN'));
    }
}

function* createMain(slice, action) {
    try {
        const { teamGroupId } = action.payload;

        const hierarchyNode = yield call(api.hierarchyNodes.get, teamGroupId);

        yield put(slice.actions.setMainHierarchyNode(hierarchyNode.data));
        return hierarchyNode.data
    } catch (error) {
        yield put(slice.actions.mainFailure(error?.response?.data ?? 'UNKNOWN'));
    }
}

function* watchCreateMain(slice) {
    yield takeLatest(slice.actions.mainStart.type, function* (action) {
        const hierarchyNode = yield call(createMain, slice, action);
        if (hierarchyNode) {
            yield call(loadMainConfigs, slice, { teamGroupId: hierarchyNode.code });
            yield call(loadLegalDocuments, slice, { teamGroupId: hierarchyNode.code });
        }
    });
}

export default function* createMainSaga() {
    yield all([
        watchCreateMain(createMainKeySlice),
    ]);
}
