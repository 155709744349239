import { neutralColors } from '../colors';

const paperStyles = {
  MuiPaper: {
    root: {
      background: neutralColors.neutralWhite,
    },
    rounded: {
      borderRadius: 24,
    },
    elevation1: {
      boxShadow: 'none',
    },
  },
  MuiPopover: {
    paper: {
      padding: '0.5rem'
    }
  }
};
export default paperStyles;
