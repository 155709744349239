import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import useAuthorizedRoutes from '../../../../hooks/UseAuthorizedRoutes';
import { useActiveNavigation } from '../../../../hooks/useActiveNavigation';

const useStyles = makeStyles(() => ({
  root: {
    padding: '8px 8px 0px 16px',
    marginBottom: 16,
    flex: 1,
    '& .MuiDrawer-paper': {
      width: 288,
      overflow: 'auto',
      position: 'relative',
      padding: 16,
      borderRadius: 16,
    },
    '& .MuiDrawer-paperAnchorDockedLeft': {
      borderRight: 'none',
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const { menus } = useAuthorizedRoutes();

  const { isNavItemActive } = useActiveNavigation(menus);

  const handleListeItemClick = (event, srcPayload) => {
    history.push(srcPayload);
  };

  return (
    <Drawer variant='permanent' anchor='left' className={classes.root}>
      <List>
        {menus.map(item => (
          <ListItem
            button
            key={item.text}
            selected={isNavItemActive(item.src)}
            autoFocus={isNavItemActive(item.src)}
            onClick={event => handleListeItemClick(event, item.src)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};
export default SideBar;
