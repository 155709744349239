import {neutralColors, themeColors} from '../colors';

const paginationStyles = {
  MuiPagination: {
    root: {
      marginTop: '24px',
      '& > *': {
        justifyContent: 'center',
      },
    },
    ul: {
      '& > li:not(:last-child)': {
        paddingRight: 8,
      },
    },
  },
  MuiPaginationItem: {
    root: {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: 24,
      borderRadius: '50% !important',
    },
    page: {
      '&:not(.Mui-selected)': {
        '&:hover': {
          backgroundColor: neutralColors.neutral100,
        }
      },
      '&:active': {
        backgroundColor: themeColors.primaryContrastText,
        color: themeColors.secondaryRegular,
        border: `2px solid ${themeColors.secondaryRegular}`,
      },
      '&:focus': {
        backgroundColor: themeColors.primaryContrastText,
        color: themeColors.secondaryRegular,
        border: `2px solid ${themeColors.secondaryRegular}`,
      },
      '&.Mui-selected': {
        backgroundColor: themeColors.primaryContrastText,
        color: themeColors.secondaryRegular,
        border: `2px solid ${themeColors.secondaryRegular}`,
        '&:hover': {
          backgroundColor: `${themeColors.primaryContrastText} !important`,
        }
      },
    },
  },
};

export default paginationStyles;
