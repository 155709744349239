import React from 'react'
import {withStyles} from "@mui/styles";
import {neutralColors} from "../../themes";

const styles = {
    dialogIndicator: {
        width: "3rem",
        height: "4px",
        borderRadius: '1.5rem',
        backgroundColor: neutralColors.neutral200,
        margin: 'auto'
    }
}

const DialogIndicator = ({ ...props }) => {
    return (
        <div className={props.classes.dialogIndicator}/>
    )
}

export default withStyles(styles)(DialogIndicator);
