import instance from '../instance';

const baseUrl = 'hierarchy-node-users/';

const hierarchyNodeUsers = {
    approveSystemFile(userId, fileId) {
        const params = new URLSearchParams();

        params.append('system-file', fileId);

        const url = `${baseUrl}${userId}/approve/`;
        return instance.post(url, {}, { params });
    }
};

export default hierarchyNodeUsers;
