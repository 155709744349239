import React from 'react';
import {withStyles} from "@mui/styles";
import {Card, Typography} from "@material-ui/core";
import {Button} from "../index";
import {createTheme} from "@material-ui/core/styles";

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'row !important',
        gap: '1rem',
        padding: '1.5rem 1.5rem 1.5rem 0 !important',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
    },
    contentDisposition: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        [createTheme().breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    }
}

const CardAction = ({ title, description, svgStyle, svgAlt = 'svg', buttonLabel, onClick, ...props }) => {
    return (
        <Card className={`${props.classes.root} selectable`} onClick={onClick}>
            <img src={svgStyle} alt={svgAlt}/>

            <div className={props.classes.contentDisposition}>
                <div className={props.classes.content}>
                    <Typography variant={'h4'} component={'h4'}>
                        {title}
                    </Typography>
                    <Typography variant={'body2'}>
                        {description}
                    </Typography>
                </div>

                <Button variant={'contained'} color={'primary'} size={'small'}>
                    {buttonLabel}
                </Button>
            </div>
        </Card>
    )
}

export default withStyles(styles)(CardAction)

