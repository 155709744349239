import systemColors from "../colors/systemColors";

const alertStyles = {
    MuiPaper: {
        root: {
            '& > MuiAlert-icon': {
                color: `${systemColors.infoRegular} !important`,
            }
        }
    }
}

export default alertStyles
