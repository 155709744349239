import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    success: false,
    loading: false,
    error: null,
};

const createUpdateRewardAllocationsSlice = (name) => createSlice({
    name: name,
    initialState,
    reducers: {
        updateStart: (state) => {
            state.success = false;
            state.loading = true;
            state.error = null;
        },
        updateSuccess: (state) => {
            state.success = true;
            state.loading = false;
            state.error = null;
        },
        updateFailure: (state, action) => {
            state.success = false;
            state.loading = false;
            state.error = action.payload;
        },
        clearUpdateRewardsAllocationsState: () => initialState,
    },
});

export const updateRewardsAllocationsKeySlice = createUpdateRewardAllocationsSlice('updateRewardsAllocationsKey');

export const {
    updateStart: updateRewardsAllocationsKeyStart,
    updateSuccess: updateRewardsAllocationsKeySuccess,
    updateFailure: updateRewardsAllocationsKeyFailure,
    clearUpdateRewardsAllocationsState: updateRewardsAllocationsKeyClear,
} = updateRewardsAllocationsKeySlice.actions;

export default {
    updateRewardsAllocationsKey: updateRewardsAllocationsKeySlice.reducer
};
