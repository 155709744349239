import {all, call, put, takeLatest} from 'redux-saga/effects';
import api from '../../../data/api/api';
import {hierarchyNodeUserSlice, setHierarchyNodeUser} from './slices';

function* fetchActiveHierarchyNodeUser(slice, endpoint, action) {
    try {
        const {contract} = action.payload;
        const {data: hierarchyNode} = yield call(endpoint, contract);
        yield put(setHierarchyNodeUser(hierarchyNode._context.highest_hierarchy_node_user));
    } catch (error) {
        yield put(slice.actions.fetchFailure(error?.response?.data?.error ?? 'UNKNOWN'));
    }
}

function* watchActiveHierarchyNodeUser(slice, endpoint) {
    yield takeLatest(slice.actions.fetchStart.type, fetchActiveHierarchyNodeUser, slice, endpoint);
}

export default function* activeHierarchyNodeUserSaga() {
    yield all([
        watchActiveHierarchyNodeUser(hierarchyNodeUserSlice, api.hierarchyNodes.get),
    ]);
}
