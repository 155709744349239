import React from 'react';

const UserPoints = () => {
    return (
        <div>
            Edenred home
        </div>
    );
};

export default UserPoints;
