import {neutralColors, themeColors} from "../colors";

const SwitchStyles = {
    MuiSwitch: {
        root: {
            width: '48px',
            height: '24px',
            padding: '0',
            borderRadius: '48px',
            '& .MuiSwitch-track': {
              backgroundColor: `${neutralColors.neutral400}`,
              opacity: `${1} !important`
            },
            '& .MuiSwitch-switchBase': {
                transition: 'transform .3s',
                transform: 'translateX(-7px) translateY(-7px)',
                '&.Mui-checked': {
                    transform: 'translateX(17px) translateY(-7px)',
                    color: neutralColors.neutralWhite
                }
            },
            '& .Mui-checked + .MuiSwitch-track': {
                backgroundColor: `${themeColors.secondaryRegular} !important`,
            }
        }
    }
}

export default SwitchStyles;
