import {all} from 'redux-saga/effects';

import {default as getContractDetailsSaga} from './getContractDetails/sagas';
import {default as updateContractDetailsSaga} from './updateContractDetails/sagas';

export default function* contractDetailsSaga() {
    yield all([
        getContractDetailsSaga(),
        updateContractDetailsSaga(),
    ]);
}
