import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleWare from "redux-saga";
import Sagas from "./sagas";
import systemFilesReducer from "../../features/systemFiles/getSystemFiles/slices";
import deleteRewardsAllocationsImageReducer from "../../features/rewardAllocations/deleteRewardAllocationsImage/slices";
import updateRewardsAllocationsImageReducer from "../../features/rewardAllocations/updateRewardAllocationsImage/slices";

const createRewardAllocationImageStore = () => {
    const sagaRewardAllocationImage = createSagaMiddleWare()

    const store = configureStore({
        reducer: {
            systemFiles: systemFilesReducer.getSystemFilesKey,
            deleteRewardAllocationImage: deleteRewardsAllocationsImageReducer.deleteRewardsAllocationsImageKey,
            updateRewardAllocationImage: updateRewardsAllocationsImageReducer.updateRewardsAllocationsImageKey,
        },
        middleware: () => [sagaRewardAllocationImage]
    });

    sagaRewardAllocationImage.run(Sagas)

    return store
};

export default createRewardAllocationImageStore;
