import React from 'react';
import { useParams } from 'react-router';
import { usePointsTransactionsQuery } from '../../features/lists/points/hook';

export const PointsList = () => {
  const { contract } = useParams();
  const { data } = usePointsTransactionsQuery({ id: contract });

  return (
    <>
      <div>{JSON.stringify(data)}</div>
    </>
  );
};
