import { configureStore } from '@reduxjs/toolkit';
import createImportReducer from '../../features/importLogs/createImport/slices'
import exportLogReducer from '../../features/importLogs/exportLog/slices'
import createSagaMiddleWare from "redux-saga";
import Sagas from "./sagas";

const createImportLogStore = () => {
    const sagaSystemFile = createSagaMiddleWare()

    const store = configureStore({
        reducer: {
            createImport: createImportReducer.createImportKey,
            exportLog: exportLogReducer.exportLogKey,
        },
        middleware: () => [sagaSystemFile]
    });

    sagaSystemFile.run(Sagas)

    return store
};

export default createImportLogStore;
