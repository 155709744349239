import React, {useState} from 'react'
import {withStyles} from "@mui/styles";
import {Checkbox, FormControl, FormControlLabel, Typography} from "@material-ui/core";
import {useIntl} from "react-intl";
import {Button} from "../../../../../../../../../../../../components";
import {RightDialogFileInput} from "../../../../../../../../../../../../components/RightDialogFileInput";
import UseSystemFile from "../../../../../../../../../../../../hooks/UseSystemFile";
import {
    deleteRewardsAllocationsImageKeyClear,
    deleteRewardsAllocationsImageKeyStart
} from "../../../../../../../../../../../../features/rewardAllocations/deleteRewardAllocationsImage/slices";
import {
    updateRewardsAllocationsImageKeyClear,
    updateRewardsAllocationsImageKeyStart
} from "../../../../../../../../../../../../features/rewardAllocations/updateRewardAllocationsImage/slices";
import {useSelector} from "react-redux";

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1rem'
    },
    enableConfigurationImage: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem'
    },
}

const ConfigurationImageComponent = ({ rewardAllocationId, code, rewardLabel, formError, ...props }) => {
    const intl = useIntl();

    const [customizeCardVisual, setCustomizeCardVisual] = useState(false);
    const [isOpenScreenRightDialog, setIsOpenScreenRightDialog] = useState(false);

    const rewardAllocationImage = useSelector(state => state.updateRewardAllocationImage);

    const uploadConfigurationImage = () => {
        setIsOpenScreenRightDialog(true)
    }

    const onFileUpdated = () => {
        setIsOpenScreenRightDialog(false)
        // re-fetch reward once images can be retrieved through them
    }

    const { updateSystemFile } = UseSystemFile({
        onFileUpdated,
        onFileDeleted: () => {},
        overrideDeleteSystemFileKey: 'deleteRewardAllocationImage',
        overrideUpdateSystemFileKey: 'updateRewardAllocationImage',
        dispatchDeleteSystemFile: deleteRewardsAllocationsImageKeyStart,
        dispatchClearDeleteSystemFile: deleteRewardsAllocationsImageKeyClear,
        dispatchUpdateSystemFile: updateRewardsAllocationsImageKeyStart,
        dispatchClearUpdateSystemFile: updateRewardsAllocationsImageKeyClear
    });

    const updateFile = (files) => {
        updateSystemFile(files[0], code, { rewardAllocationId })
    }

    return (
        <div className={props.classes.root}>
            <FormControl required style={{ display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                <FormControlLabel
                    control={<Checkbox checked={customizeCardVisual} className={'root'} disableRipple checkedIcon={<span className={'icon checkedIcon'} />}
                                       icon={<span className={'icon'} />} inputProps={{ 'aria-label': 'decorative checkbox' }}
                                       onChange={(e) => setCustomizeCardVisual(e.target.checked)}/>}
                    label={intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.parameters.rewards.customizeCardVisualLabel'})} style={{ margin: 0 }}
                />
            </FormControl>

            {customizeCardVisual && (
                <div className={props.classes.root}>
                    {rewardAllocationImage.imageUpdated &&
                        <Typography variant={'body1'}>
                            {rewardAllocationImage.imageUpdated.filename}
                        </Typography>
                    }

                    <Button color={'secondary'} variant={'outlined'} size={'small'} onClick={uploadConfigurationImage}>
                        {rewardAllocationImage.imageUpdated === null ? intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.parameters.rewards.importCardVisualActionButton'}) : intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.parameters.rewards.replaceCardVisualActionButton'})}
                    </Button>
                </div>
            )}


            <RightDialogFileInput open={isOpenScreenRightDialog}
                                  title={intl.formatMessage({id: 'spider.hierarchy_node.platform_customization.parameters.rewards.customizeCardVisualLUploadTitle'}).format(rewardLabel)}
                                  authorizedFileTypes={['JPG', 'JPEG', 'PNG']}
                                  maxSize={10}
                                  setOpen={(value) => setIsOpenScreenRightDialog(value)}
                                  onValid={updateFile}
            />
        </div>
    )
}

export default withStyles(styles)(ConfigurationImageComponent)
