import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    rewards: null,
    success: false,
    loading: false,
    error: null,
};

const createGetRewardsSlice = (name) => createSlice({
    name: name,
    initialState,
    reducers: {
        fetchStart: (state) => {
            state.success = false;
            state.loading = true;
            state.error = null;
        },
        fetchSuccess: (state, data) => {
            state.rewards = data.payload.data
            state.success = true;
            state.loading = false;
            state.error = null;
        },
        fetchFailure: (state, action) => {
            state.success = false;
            state.loading = false;
            state.error = action.payload;
        },
        clearRewardsState: () => initialState,
    },
});

export const getRewardsKeySlice = createGetRewardsSlice('getRewardsKey');

export const {
    fetchStart: getRewardsKeyStart,
    fetchSuccess: getRewardsKeySuccess,
    fetchFailure: getRewardsKeyFailure,
    clearRewardsState: getRewardsKeyClear,
} = getRewardsKeySlice.actions;

export default {
    getRewardsKey: getRewardsKeySlice.reducer,
};
