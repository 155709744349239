import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    success: false,
    loading: false,
    error: null,
};

const createUpdateContractDetailsSlice = (name) => createSlice({
    name: name,
    initialState,
    reducers: {
        updateStart: (state) => {
            state.success = false;
            state.loading = true;
            state.error = null;
        },
        updateSuccess: (state) => {
            state.success = true;
            state.loading = false;
            state.error = null;
        },
        updateFailure: (state, action) => {
            state.success = false;
            state.loading = false;
            state.error = action.payload;
        },
        clearUpdateContractDetailsState: () => initialState,
    },
});

export const updateContractDetailsKeySlice = createUpdateContractDetailsSlice('updateContractDetailsKey');

export const {
    updateStart: updateContractDetailsKeyStart,
    updateSuccess: updateContractDetailsKeySuccess,
    updateFailure: updateContractDetailsKeyFailure,
    clearUpdateContractDetailsState: updateContractDetailsKeyClear,
} = updateContractDetailsKeySlice.actions;

export default {
    updateContractDetailsKey: updateContractDetailsKeySlice.reducer
};
