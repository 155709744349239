import React, { useCallback, useEffect, useRef } from 'react';

import { connect } from 'react-redux';

const OneSignalInit = ({ goBack, children, ...props }) => {
    const oneSignalInitialized = useRef();

    const initOneSignal = useCallback((info) => {
        if (window.ReactNativeWebView?.postMessage && info?.loading !== true) {
            setTimeout(function () {
                if (info?.account?.identifier) {
                    const message = {
                        source: 'firetiger',
                        action: 'setExternalUserId',
                        id: info.account.identifier,
                    };
                    window.ReactNativeWebView.postMessage(JSON.stringify(message));
                    oneSignalInitialized.current = true;
                } else {
                    window.ReactNativeWebView.postMessage(JSON.stringify({action: 'unsetExternalUserId'}));
                    oneSignalInitialized.current = false;
                }
            }, 1);
        }
    }, []);

    useEffect(() => {
        if (oneSignalInitialized.current !== !!props.accountDetail) {
            initOneSignal(props.accountDetail);
        }
    }, [props.accountDetail, initOneSignal]);

    return <></>;
};

const mapStateToProps = ({accountDetail}) => ({
    accountDetail,
});

export default connect(mapStateToProps)(OneSignalInit);
