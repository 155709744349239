import React from 'react';
import {Route} from "react-router-dom";

import {UserLayout} from './index';
import {GuestLayout} from '../GuestLayout';
import {AuthProvider} from '../../../../auth';
import useAuthorizedUser from "../../../hooks/UseAuthorizedUser";

const UserRoutes = ({component: Component, useGuestLayout = false, authorizedRoles = [], ...rest}) => {
    useAuthorizedUser(authorizedRoles);
    const Layout = !useGuestLayout ? UserLayout : GuestLayout;

    return (
        <Route
            {...rest}
            render={props => (
                <AuthProvider>
                    <Layout component={Component} authorizedRoles={authorizedRoles} {...props}/>
                </AuthProvider>
            )}
        />
    );
};

export default UserRoutes;
