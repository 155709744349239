import { useDispatch, useSelector } from 'react-redux';
import { getPointsTransactionsListKeyStart } from './slices';
import { useCallback, useEffect } from 'react';

/**
 * @typedef {import("./types").GetPointsTransitionsResponse} GetPointsTransitionsResponse
 * @description Hook to query the points transactions of a contract (hierarchy node).
 * @returns {{data: GetPointsTransitionsResponse|null, success: boolean, error: {}|null, refetch: (params) => void}}
 */
export const usePointsTransactionsQuery = params => {
  const dispatch = useDispatch();
  const { data, success, error, loading } = useSelector(
    state => state.getPointsTransactionsKey,
  );

  const fetchFn = useCallback(
    p => {
      dispatch(getPointsTransactionsListKeyStart(p));
    },
    [dispatch],
  );

  useEffect(() => {
    fetchFn(params);
  }, [params, fetchFn]);

  const refetch = useCallback(
    p => {
      fetchFn(p);
    },
    [fetchFn],
  );

  return { data, success, error, loading, refetch };
};
