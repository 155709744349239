import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import useHandleToast from "./UseHandleToast";
import {createImportKeyClear, createImportKeyStart} from "../features/importLogs/createImport/slices";
import {exportLogKeyStart} from "../features/importLogs/exportLog/slices";
import {ImportLogsStatuses} from "../enums/importLogsStatuses";
import {useIntl} from "react-intl";
import useFileDownload from "./UseFileDownload";

const UseImportLogFile = ({ onFileCreated, onFileExported, overrideCreateImportKey = 'createImportKey', overrideExportLogKey = 'exportLogKey' }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const importLogCreated = useSelector(state => state[overrideCreateImportKey]);
    const importLogExported = useSelector(state => state[overrideExportLogKey]);
    const { downloadBlob } = useFileDownload();
    const [listener, setListener] = useState(false)

    const clearListener = () => {
        setListener(false)
    }

    useHandleToast(importLogCreated, dispatch, createImportKeyClear,
        'spider.importLog.create.success',
        'spider.importLog.create.error', listener, clearListener)

    const createImportLog = (fileData, type, teamGroupId, hierarchyNodeUserId) => {
        setListener(true)
        const file = new FormData();
        file.append('type', type);
        file.append('file', fileData);
        file.append('hierarchy_node_user', hierarchyNodeUserId);
        dispatch(createImportKeyStart({ importFile: file, teamGroupId }))
    }

    const exportImportLog = (importLogId, teamGroupId) => {
        setListener(true)
        dispatch(exportLogKeyStart({ teamGroupId, importLogId }))
    }

    useEffect(() => {
        if (!listener) return

        if (importLogCreated.import !== null) {
            onFileCreated(importLogCreated.import)
        }

        if (importLogExported.success) {
            const file = new Blob([importLogExported.logFile.data], {
                type: 'text/csv'
            })
            downloadBlob(file, 'export.csv')
            onFileExported()
        }
    }, [importLogCreated, importLogExported, dispatch]);

    const getChipsLabel = (status) => {
        let chipStatus
        switch (status) {
            case ImportLogsStatuses.FAILED:
                chipStatus = intl.formatMessage({id: 'spider.importLog.status.failed'})
                break
            case ImportLogsStatuses.FINISHED:
                chipStatus = intl.formatMessage({id: 'spider.importLog.status.finished'})
                break
            case ImportLogsStatuses.STARTED:
                chipStatus = intl.formatMessage({id: 'spider.importLog.status.started'})
                break
            default:
                chipStatus = intl.formatMessage({id: 'spider.importLog.status.pending'})
                break
        }

        return chipStatus
    }

    const getChipsStatus = (status) => {
        let chipStatus
        switch (status) {
            case ImportLogsStatuses.FAILED:
                chipStatus = 'error'
                break
            case ImportLogsStatuses.FINISHED:
                chipStatus = 'imported'
                break
            default:
                chipStatus = 'inactive'
                break
        }

        return chipStatus
    }

    return { getChipsStatus, getChipsLabel, createImportLog, exportImportLog }
}

export default UseImportLogFile;
