import React, { Component } from 'react';
import lottie from 'lottie-web';
import * as animationData from '../../../../../assets/lottie/rocket.json';


class RocketAnimation extends Component {
  componentDidMount() {
    const animation = lottie.loadAnimation({
      container: document.getElementById('rocket-animation'),
      renderer: 'svg',
      loop: true,
      autoplay: false,
      animationData: animationData.default,
    });
    animation.addEventListener('DOMLoaded', function () {
      animation.play()
    });
  }

  render() {
    return (
            <div 
            id="rocket-animation"
            style={{ width: 150, height: 100 }}
            ref={(ref) => (this.ref = ref)}
            ></div>
    );
  }
}

export default RocketAnimation;
