const PaginationHelper = (pagination, params) => {
    if (!pagination) return params

    if (pagination.page) {
        params.append('page', pagination.page);
    }
    if (pagination.rowsPerPage) {
        params.append('page_size', pagination.rowsPerPage);
    }

    return params
}

export default PaginationHelper;
