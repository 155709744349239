import React, {useEffect, useState} from 'react'
import createRewardAllocationImageStore from "../../../../../../../../../../../../store/rewardImage/store";
import {Provider} from "react-redux";
import {ConfigurationImageComponent} from "../ConfigurationImageComponent";

const ConfigurationImage = ({ ...props }) => {
    const store = createRewardAllocationImageStore();
    const [showComponent, setShowComponent] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setShowComponent(true)
        }, 100)
    }, []);

    return (
        <Provider store={store}>
            {showComponent && (<ConfigurationImageComponent {...props}/>)}
        </Provider>
    );
}

export default ConfigurationImage
