import React from 'react'
import createImportLogStore from "../../store/importLog/store";
import {Provider} from "react-redux";
import {ImportDialogComponent} from "./components/ImportDialogComponent";

const ImportDialog = ({ ...props }) => {
    const store = createImportLogStore();

    return (
        <Provider store={store}>
            <ImportDialogComponent {...props}/>
        </Provider>
    )
}

export default ImportDialog;
