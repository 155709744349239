import {Dialog} from "@material-ui/core";
import React from "react";
import {createTheme, withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

const styles = {
    paper: {
        position: 'absolute',
        maxHeight: '100vh',
        maxWidth: '100vw !important',
        padding: 0,
        margin: 0,
        borderRadius: 0,
        right: 0,
        height: '100vh',
        width: '100vw',
        [createTheme().breakpoints.up('md')]: {
            width: '31.25rem',
        },
        '& > div:first-child, & > form > div:first-child': {
            marginTop: 0,
            paddingTop: 0,
        },
        '& > div:nth-child(2), & > div:nth-child(3), & > form > div:nth-child(2), & > form > div:nth-child(3)': {
            marginTop: 0,
        },
    }
};

const DialogComponent = withStyles(styles)(Dialog);

const ScreenRightDialog = ({ width = 'xs', direction, ...props }) => {
    return (
        <React.Fragment>
            <DialogComponent {...props}/>
        </React.Fragment>
    );
}

export default withWidth()(ScreenRightDialog);
