import {all} from 'redux-saga/effects';

import {default as createImportSaga} from '../../features/importLogs/createImport/sagas';
import {default as exportLogSaga} from '../../features/importLogs/exportLog/sagas';

export default function* importLogSaga() {
    yield all([
        createImportSaga(),
        exportLogSaga(),
    ]);
}
