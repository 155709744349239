import React from 'react';

const Orders = () => {
    return (
        <div>
            Edenred home
        </div>
    );
};

export default Orders;
