import {all} from 'redux-saga/effects';

import {default as getSystemFileSaga} from '../../features/systemFiles/getSystemFiles/sagas';
import {default as updateRewardAllocationImageSaga} from '../../features/rewardAllocations/updateRewardAllocationsImage/sagas';
import {default as deleteRewardAllocationImageSaga} from '../../features/rewardAllocations/deleteRewardAllocationsImage/sagas';

export default function* rewardImageSaga() {
    yield all([
        getSystemFileSaga(),
        updateRewardAllocationImageSaga(),
        deleteRewardAllocationImageSaga()
    ]);
}
