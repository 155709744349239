import React, {useEffect, useState} from 'react';
import {
    Checkbox,
    Dialog,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Typography
} from "@material-ui/core";
import Button from "../Button";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import useHandleToast from "../../hooks/UseHandleToast";
import {
    approveSystemFilesKeyClear,
    approveSystemFilesKeyStart
} from "../../features/systemFiles/approveSystemFiles/slices";
import {useAuth} from "../../../auth";

const DocumentApproval = ({ title, checkBoxValidationLabel, checkBoxValidationErrorLabel, document, onDialogClose, ...props }) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const [documentSrc, setDocumentSrc] = useState(null);
    const [isDocumentApproved, setIsDocumentApproved] = useState(false);
    const [formError, setFormError] = useState(false)
    const { hierarchyNodeUser } = useAuth();

    const systemFileApproved = useSelector(state => state.approveSystemFilesKey);

    useEffect(() => {
        if (!document) {
            return false
        }

        const request = new XMLHttpRequest();
        request.open('GET', document.src, true);
        request.responseType = 'blob';
        request.onload = function() {
            const reader = new FileReader();
            reader.readAsDataURL(request.response);
            reader.onload =  function(e){
                setDocumentSrc(e.target.result)
            };
        };
        request.send();

    }, [document]);

    useEffect(() => {
        if (systemFileApproved.success) {
            setDocumentSrc(null)
            onDialogClose()
        }
    }, [systemFileApproved]);

    useHandleToast(systemFileApproved, dispatch, approveSystemFilesKeyClear,
        'spider.systemFile.approve.success',
        'spider.systemFile.approve.error')

    const checkApproval = (event) => {
        setIsDocumentApproved(event.target.checked)
        if (formError) {
            setFormError(false)
        }
    }

    const approveDocument = () => {
        if (!isDocumentApproved) {
            setFormError(true)
            return
        }

        dispatch(approveSystemFilesKeyStart({ userId: hierarchyNodeUser.uuid, fileId: document.uuid }))
    }

    return (
        <Dialog {...props} maxWidth={'lg'} fullWidth={true}>
            <DialogContent style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', padding: '0rem 2rem 2rem 2rem', marginX: 'auto', gap: '1rem' }}>
                <Typography variant={'h1'} component={'h1'}>
                    {title}
                </Typography>

                <div>
                    {!document && <>{intl.formatMessage({id: 'spider.documentApproval.documentUnavailable'})}</> }
                    {(document && documentSrc) && <iframe title={document.filename} src={`${documentSrc}#view=FitH&toolbar=0&navpanes=0`}
                                                          style={{ borderRadius: '16px', overflow: 'clip', border: 'none' }} height={'570px'} width="100%"/>}
                </div>

                <FormControl required error={formError} style={{ display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                    <FormControlLabel
                        control={<Checkbox checked={isDocumentApproved} className={'root'} disableRipple checkedIcon={<span className={'icon checkedIcon'} />}
                                           icon={<span className={'icon'} />} inputProps={{ 'aria-label': 'decorative checkbox' }}
                                           onChange={checkApproval}/>}
                        label={checkBoxValidationLabel} style={{ margin: 0 }}
                    />
                    { formError && <FormHelperText>{checkBoxValidationErrorLabel}</FormHelperText>}
                </FormControl>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant={'contained'} color={'primary'} size={'small'} onClick={approveDocument}>
                        {intl.formatMessage({id: 'spider.documentApproval.saveAction'})}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default DocumentApproval;
