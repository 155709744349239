import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../data/api/api';
import {createImportKeySlice} from './slices';

function* createImport(slice, endpoint, action) {
    try {
        const { teamGroupId, importFile } = action.payload;
        const data = yield call(endpoint, teamGroupId, importFile);
        yield put(slice.actions.createSuccess(data));
    } catch (error) {
        yield put(slice.actions.createFailure(error?.response?.data?.error ?? 'UNKNOWN'));
    }
}

function* watchCreateImport(slice, endpoint) {
    yield takeLatest(slice.actions.createStart.type, createImport, slice, endpoint);
}

export default function* createImportSaga() {
    yield all([
        watchCreateImport(createImportKeySlice, api.importLogs.createImport),
    ]);
}
