import {useIntl} from "react-intl";
import useFileDownload from "../UseFileDownload";

const UsePointHelper = () => {
    const intl = useIntl();
    const { downloadBlob } = useFileDownload();

    const onDownloadImportPattern = () => {
        const file = new Blob(['MATRICULE;POINTS;COMMENTAIRE\r\n'], {
            type: 'text/csv'
        })
        downloadBlob(file, 'points-modele.csv')
    }

    const importRequirements = {
        helpInfo: {
            helpInfo: [
                intl.formatMessage({id: 'spider.points.helpImport.helpInfo.waitedFormat'}),
                intl.formatMessage({id: 'spider.points.helpImport.helpInfo.orderFormat'}),
                intl.formatMessage({id: 'spider.points.helpImport.helpInfo.optionalInfo'})
            ],

            requirement: [
                {
                    title: intl.formatMessage({id: 'spider.points.helpImport.requirements.number.title'}),
                    description: intl.formatMessage({id: 'spider.points.helpImport.requirements.number.description'}),
                    required: true
                },
                {
                    title: intl.formatMessage({id: 'spider.points.helpImport.requirements.points.title'}),
                    description: intl.formatMessage({id: 'spider.points.helpImport.requirements.points.description'}),
                    required: true,
                    category: null
                },
                {
                    title: intl.formatMessage({id: 'spider.points.helpImport.requirements.comment.title'}),
                    description: intl.formatMessage({id: 'spider.points.helpImport.requirements.comment.description'}),
                    required: false,
                    category: null
                }
            ]
        }
    }

    return { importRequirements, onDownloadImportPattern }
}

export default UsePointHelper;
